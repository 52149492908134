






























































import wAxios from '@/plugins/w/axios'

const api = {
  groups:     (params?)       => wAxios.get_auth(`/v1/group-admin/groups`, { params }),
  startExam:  (examId, data)  => wAxios.post_auth(`/v1/teacher/examtemplates/${examId}/start`, data),
}

export default {
  props: { 
    modalId: { required: true },
    exam: { required: true }
  },

  data() {
    return {
      showPublicFlagCheckbox: true,
      startExamPublicFlag: true,
      startExamName: '',
      startExamGroup: null,
      studentGroups: [
        { value: null, text: 'Žiadna skupina' }
      ],
    }
  },

  async mounted() {
    try {
      const { data: { data } } = await api.groups()
      this.studentGroups = this.studentGroups.concat(data?.map(group => ({ value: group.id, text: group.name })) || [])
    } catch(err) {
      this.$wToast.error(err)
    }
  },

  watch: {
    startExamGroup: function(newValue) {
      if (newValue) {
        this.showPublicFlagCheckbox = false
        this.startExamPublicFlag = false
        return
      }

      this.showPublicFlagCheckbox = true
    }
  },

  methods: {
    close() {
      this.$bvModal.hide(this.modalId)
    },

    async start() {
      try { 
        const { data: { data: startedExam }} = await api.startExam(this.exam.id, {
          name: this.startExamName, 
          teacher_group_id: this.startExamGroup, 
          is_auth_required: !this.startExamPublicFlag
        })
        this.$emit('started', startedExam)
      } catch(err) {
        this.$wToast.error(err)
      }
    },

    cancelStart() {
      this.$emit('cancelStart')
      this.close()
    }
  }
}
